import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { NavigateToNextPageAction } from '../navigateToNextPage/navigateToNextPage';
import settingsParams from '../../components/BookOnline/settingsParams';
import { ServiceClickTarget } from '../../types/types';
import { CatalogServiceDto } from '@wix/bookings-uou-types';
import { ReferralInfo } from '../../utils/bi/consts';
import { NavigationTarget } from '../navigateToNextPage/consts';
import { OnBookFlowStartAttemptAction } from '../onBookFlowStartAttempt/onBookFlowStartAttempt';
import { WidgetViewModel } from '../../viewModel/viewModel';
import { setServiceLoader } from '../../utils/setServiceLoader/setServiceLoader';

export type OnServiceClickAction = ({
  service,
  referralInfo,
}: {
  service: CatalogServiceDto;
  referralInfo: ReferralInfo;
}) => Promise<void>;

export const createOnServiceClickAction = ({
  navigateToNextPage,
  flowAPI,
  onBookFlowStartAttempt,
  setProps,
  widgetViewModel,
}: {
  navigateToNextPage: NavigateToNextPageAction;
  flowAPI: ControllerFlowAPI;
  onBookFlowStartAttempt: OnBookFlowStartAttemptAction;
  setProps: Function;
  widgetViewModel: WidgetViewModel;
}): OnServiceClickAction => {
  return async ({
    service,
    referralInfo,
  }: {
    service: CatalogServiceDto;
    referralInfo: ReferralInfo;
  }) => {
    setServiceLoader({ setProps, widgetViewModel, service });

    const target =
      flowAPI.settings.get(settingsParams.serviceClickTarget) ===
      ServiceClickTarget.SERVICE_PAGE
        ? NavigationTarget.SERVICE_PAGE
        : NavigationTarget.CALENDAR_PAGE;

    switch (flowAPI.settings.get(settingsParams.serviceClickTarget)) {
      case ServiceClickTarget.SERVICE_PAGE:
        return navigateToNextPage({ target, service, referralInfo });
      case ServiceClickTarget.BOOKING_CALENDAR:
        return onBookFlowStartAttempt({ service, referralInfo });
    }
  };
};
